var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pricing-page"
  }, [_vm.currentPlan === 'lite' ? _c('div', {
    staticClass: "free-trial-banner"
  }, [_vm._m(0), _c('Button', {
    staticClass: "trial-button",
    attrs: {
      "variant": "warning"
    },
    on: {
      "onClick": _vm.startFreeTrial
    }
  }, [_vm._v(" Kostenlos testen ")])], 1) : _vm._e(), _vm._m(1), _c('div', {
    staticClass: "pricing-container"
  }, [_c('div', {
    staticClass: "plan-cards-container"
  }, _vm._l(_vm.plans, function (plan) {
    return _c('div', {
      key: plan.id,
      staticClass: "plan-card",
      class: [{
        'plan-card--selected': _vm.selectedPlan === plan.id
      }, "plan-card--".concat(plan.id)],
      on: {
        "click": function click($event) {
          return _vm.selectPlan(plan.id);
        }
      }
    }, [plan.id === 'basic' ? _c('div', {
      staticClass: "plan-badge"
    }, [_vm._v("Empfohlen")]) : _vm._e(), _c('div', {
      staticClass: "plan-card__header"
    }, [_c('h3', {
      staticClass: "plan-name"
    }, [_vm._v(_vm._s(plan.name))]), _c('div', {
      staticClass: "plan-price"
    }, [_c('span', {
      staticClass: "amount"
    }, [_vm._v(_vm._s(plan.price) + "€")]), _c('span', {
      staticClass: "period"
    }, [_vm._v("pro Monat / pro Taxi")])])]), _c('div', {
      staticClass: "plan-card__body"
    }, [_c('p', {
      staticClass: "plan-description"
    }, [_vm._v(_vm._s(plan.description))]), _c('ul', {
      staticClass: "feature-list"
    }, _vm._l(plan.features, function (feature, index) {
      return _c('li', {
        key: index,
        staticClass: "feature-item"
      }, [_vm._m(2, true), _c('span', {
        staticClass: "feature-text"
      }, [_vm._v(_vm._s(feature))])]);
    }), 0)]), plan.id === 'basic' ? _c('div', {
      staticClass: "discount-info"
    }, [_c('span', {
      staticClass: "discount-label"
    }, [_vm._v("Rabatte")]), _vm._m(3, true)]) : _vm._e(), _c('div', {
      staticClass: "plan-card__footer"
    }, [_c('Button', {
      staticClass: "select-plan-btn",
      attrs: {
        "variant": _vm.selectedPlan === plan.id ? 'warning' : 'plain'
      },
      on: {
        "onClick": function onClick($event) {
          return _vm.selectPlan(plan.id);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.selectedPlan === plan.id ? 'Ausgewählt' : 'Tarif wählen') + " ")]), plan.id === 'lite' && _vm.currentPlan === 'lite' ? _c('p', {
      staticClass: "current-plan-badge"
    }, [_c('i', {
      staticClass: "ri-checkbox-circle-fill"
    }), _vm._v(" Ihr aktueller Tarif ")]) : _vm._e()], 1)]);
  }), 0), _c('div', {
    staticClass: "modules-section",
    class: {
      'modules-section--dimmed': !_vm.selectedPlan
    }
  }, [_c('h2', {
    staticClass: "section-title"
  }, [_vm._v("Erweitern Sie Ihren Tarif mit Zusatzmodulen")]), _c('p', {
    staticClass: "section-subtitle"
  }, [_vm._v(" Passen Sie Ihre Erfahrung mit zusätzlicher Funktionalität an ")]), _c('div', {
    staticClass: "modules-grid"
  }, _vm._l(_vm.availableModules, function (module) {
    return _c('div', {
      key: module.id,
      staticClass: "module-card",
      class: {
        'module-card--selected': _vm.selectedModules.includes(module.id),
        'module-card--included': _vm.isModuleIncluded(module.id),
        'module-card--unavailable': !_vm.selectedPlan
      },
      on: {
        "click": function click($event) {
          return _vm.toggleModule(module.id);
        }
      }
    }, [_c('div', {
      staticClass: "module-icon"
    }, [_c('i', {
      class: module.icon
    })]), _c('div', {
      staticClass: "module-content"
    }, [_c('h4', {
      staticClass: "module-title"
    }, [_vm._v(_vm._s(module.name))]), _c('p', {
      staticClass: "module-description"
    }, [_vm._v(_vm._s(module.description))])]), _c('div', {
      staticClass: "module-footer"
    }, [!_vm.isModuleIncluded(module.id) ? _c('div', {
      staticClass: "module-price"
    }, [_vm._v(" " + _vm._s(_vm.formatPrice(module.price)) + "€ "), _c('span', {
      staticClass: "period"
    }, [_vm._v("/ monat")])]) : _c('div', {
      staticClass: "module-included"
    }, [_c('i', {
      staticClass: "ri-check-double-line"
    }), _vm._v(" Im Tarif enthalten ")]), _c('div', {
      staticClass: "module-select"
    }, [_c('div', {
      staticClass: "toggle-switch",
      class: {
        'toggle-switch--on': _vm.selectedModules.includes(module.id),
        'toggle-switch--disabled': _vm.isModuleIncluded(module.id) || !_vm.selectedPlan
      }
    }, [_c('div', {
      staticClass: "toggle-slider"
    })])])])]);
  }), 0)]), _vm.hasChangedPlan || _vm.selectedModules.length > 0 ? _c('div', {
    staticClass: "checkout-section"
  }, [_c('div', {
    staticClass: "order-summary"
  }, [_c('div', {
    staticClass: "summary-header",
    on: {
      "click": _vm.toggleSummaryDetails
    }
  }, [_c('h3', [_vm._v("Bestellübersicht:")]), _vm.selectedPlan ? _c('div', {
    staticClass: "summary-collapsed"
  }, [_c('span', {
    staticClass: "summary-info"
  }, [_vm._v(" " + _vm._s(_vm.getSelectedPlanName()) + " Tarif + " + _vm._s(_vm.selectedModules.length) + " Module ")]), _c('i', {
    staticClass: "toggle-icon ri-arrow-down-s-line",
    class: {
      expanded: _vm.summaryExpanded
    }
  })]) : _vm._e()]), _vm.selectedPlan ? _c('div', {
    staticClass: "summary-details",
    class: {
      expanded: _vm.summaryExpanded
    }
  }, [_c('div', {
    staticClass: "summary-items"
  }, [_c('div', {
    staticClass: "summary-item"
  }, [_c('span', {
    staticClass: "item-name"
  }, [_vm._v(_vm._s(_vm.getSelectedPlanName()) + " Tarif")]), _c('span', {
    staticClass: "item-price"
  }, [_vm._v(_vm._s(_vm.getSelectedPlanPrice()) + "€")])]), _vm._l(_vm.selectedModules, function (moduleId) {
    return _c('div', {
      key: moduleId,
      staticClass: "summary-item"
    }, [_c('span', {
      staticClass: "item-name"
    }, [_vm._v(_vm._s(_vm.getModuleName(moduleId)))]), _c('span', {
      staticClass: "item-price"
    }, [_vm._v(_vm._s(_vm.getModulePrice(moduleId)) + "€")])]);
  })], 2)]) : _c('div', {
    staticClass: "empty-selection"
  }, [_c('i', {
    staticClass: "ri-information-line"
  }), _c('p', [_vm._v("Bitte wählen Sie einen Tarif")])]), _vm.selectedPlan ? _c('div', {
    staticClass: "summary-total"
  }, [_c('span', {
    staticClass: "total-label"
  }, [_vm._v("Gesamt (monatlich):")]), _c('span', {
    staticClass: "total-price"
  }, [_vm._v(_vm._s(_vm.calculateTotal()) + "€")])]) : _vm._e(), _vm._m(4)]), _c('Button', {
    attrs: {
      "variant": "warning",
      "disabled": !_vm.selectedPlan || _vm.isProcessing
    },
    on: {
      "onClick": _vm.handleCheckout
    }
  }, [_vm._v(" " + _vm._s(_vm.isProcessing ? 'Wird bearbeitet...' : 'Jetzt upgraden') + " "), !_vm.isProcessing ? _c('i', {
    staticClass: "ri-arrow-right-up-line"
  }) : _vm._e(), _vm.isProcessing ? _c('span', {
    staticClass: "loading-spinner"
  }) : _vm._e()])], 1) : _vm._e()]), _c('div', {
    staticClass: "feature-comparison"
  }, [_c('h2', {
    staticClass: "comparison-title"
  }, [_vm._v("Funktionsvergleich der Pläne")]), _c('div', {
    staticClass: "comparison-table-container"
  }, [_c('table', {
    staticClass: "comparison-table"
  }, [_vm._m(5), _c('tbody', _vm._l(_vm.featureComparison, function (feature, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      staticClass: "feature-name"
    }, [_vm._v(_vm._s(feature.name))]), _c('td', {
      staticClass: "lite-value"
    }, [_c('i', {
      class: feature.lite ? 'ri-check-line included' : 'ri-close-line not-included'
    }), typeof feature.lite === 'string' ? _c('span', [_vm._v(_vm._s(feature.lite))]) : _vm._e()]), _c('td', {
      staticClass: "basic-value"
    }, [_c('i', {
      class: feature.basic ? 'ri-check-line included' : 'ri-close-line not-included'
    }), typeof feature.basic === 'string' ? _c('span', [_vm._v(_vm._s(feature.basic))]) : _vm._e()])]);
  }), 0)])])]), _c('div', {
    staticClass: "faq-section"
  }, [_c('h2', {
    staticClass: "faq-title"
  }, [_vm._v("Häufig gestellte Fragen")]), _c('div', {
    staticClass: "faq-container"
  }, _vm._l(_vm.faqs, function (faq, index) {
    return _c('div', {
      key: index,
      staticClass: "faq-item",
      class: {
        'faq-item--open': _vm.openFaq === index
      },
      on: {
        "click": function click($event) {
          return _vm.toggleFaq(index);
        }
      }
    }, [_c('div', {
      staticClass: "faq-question"
    }, [_c('h4', [_vm._v(_vm._s(faq.question))]), _c('i', {
      class: _vm.openFaq === index ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'
    })]), _vm.openFaq === index ? _c('div', {
      staticClass: "faq-answer"
    }, [_c('p', [_vm._v(_vm._s(faq.answer))])]) : _vm._e()]);
  }), 0)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "banner-content"
  }, [_c('i', {
    staticClass: "ri-gift-line"
  }), _c('div', {
    staticClass: "banner-text"
  }, [_c('h3', [_vm._v("1 Monat kostenlos testen!")]), _c('p', [_vm._v(" Als Lite-Nutzer können Sie den Basic-Tarif einen Monat kostenlos testen. ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pricing-header"
  }, [_c('h1', [_vm._v("Verbessern Sie Ihre TaxiFusion Erfahrung")]), _c('p', {
    staticClass: "subtitle"
  }, [_vm._v(" Wählen Sie den Tarif, der zu Ihrem Unternehmen passt oder erweitern Sie mit einzelnen Modulen ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "feature-icon"
  }, [_c('i', {
    staticClass: "ri-check-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ul', {
    staticClass: "discount-list"
  }, [_c('li', [_vm._v("Für Einzelfahrer 13€")]), _c('li', [_vm._v("Ab 10 Autos 15,20€")]), _c('li', [_vm._v("Ab 20 Autos 14,60€")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tax-info"
  }, [_c('i', {
    staticClass: "ri-information-line"
  }), _c('span', [_vm._v("Alle Preise zzgl. MwSt.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "feature-col"
  }, [_vm._v("Funktion")]), _c('th', {
    staticClass: "lite-col"
  }, [_vm._v("Lite")]), _c('th', {
    staticClass: "basic-col"
  }, [_vm._v("Basic")])])]);
}]

export { render, staticRenderFns }