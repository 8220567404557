<template>
    <div class="pricing-page">
        <!-- Free Trial Banner -->
        <div v-if="currentPlan === 'lite'" class="free-trial-banner">
            <div class="banner-content">
                <i class="ri-gift-line"></i>
                <div class="banner-text">
                    <h3>1 Monat kostenlos testen!</h3>
                    <p>
                        Als Lite-Nutzer können Sie den Basic-Tarif einen Monat kostenlos testen.
                    </p>
                </div>
            </div>
            <Button variant="warning" @onClick="startFreeTrial" class="trial-button">
                Kostenlos testen
            </Button>
        </div>
        <div class="pricing-header">
            <h1>Verbessern Sie Ihre TaxiFusion Erfahrung</h1>
            <p class="subtitle">
                Wählen Sie den Tarif, der zu Ihrem Unternehmen passt oder erweitern Sie mit
                einzelnen Modulen
            </p>
        </div>

        <div class="pricing-container">
            <!-- Tarif Cards Section -->
            <div class="plan-cards-container">
                <div
                    v-for="plan in plans"
                    :key="plan.id"
                    class="plan-card"
                    :class="[
                        { 'plan-card--selected': selectedPlan === plan.id },
                        `plan-card--${plan.id}`,
                    ]"
                    @click="selectPlan(plan.id)"
                >
                    <div v-if="plan.id === 'basic'" class="plan-badge">Empfohlen</div>

                    <div class="plan-card__header">
                        <h3 class="plan-name">{{ plan.name }}</h3>
                        <div class="plan-price">
                            <span class="amount">{{ plan.price }}€</span>
                            <span class="period">pro Monat / pro Taxi</span>
                        </div>
                    </div>

                    <div class="plan-card__body">
                        <p class="plan-description">{{ plan.description }}</p>

                        <ul class="feature-list">
                            <li
                                v-for="(feature, index) in plan.features"
                                :key="index"
                                class="feature-item"
                            >
                                <span class="feature-icon">
                                    <i class="ri-check-line"></i>
                                </span>
                                <span class="feature-text">{{ feature }}</span>
                            </li>
                        </ul>
                    </div>
                    <div v-if="plan.id === 'basic'" class="discount-info">
                        <span class="discount-label">Rabatte</span>
                        <ul class="discount-list">
                            <li>Für Einzelfahrer 13€</li>
                            <li>Ab 10 Autos 15,20€</li>
                            <li>Ab 20 Autos 14,60€</li>
                        </ul>
                    </div>
                    <div class="plan-card__footer">
                        <Button
                            :variant="selectedPlan === plan.id ? 'warning' : 'plain'"
                            class="select-plan-btn"
                            @onClick="selectPlan(plan.id)"
                        >
                            {{ selectedPlan === plan.id ? 'Ausgewählt' : 'Tarif wählen' }}
                        </Button>

                        <p
                            v-if="plan.id === 'lite' && currentPlan === 'lite'"
                            class="current-plan-badge"
                        >
                            <i class="ri-checkbox-circle-fill"></i> Ihr aktueller Tarif
                        </p>
                    </div>
                </div>
            </div>

            <!-- Optional Modules Section -->
            <div class="modules-section" :class="{ 'modules-section--dimmed': !selectedPlan }">
                <h2 class="section-title">Erweitern Sie Ihren Tarif mit Zusatzmodulen</h2>
                <p class="section-subtitle">
                    Passen Sie Ihre Erfahrung mit zusätzlicher Funktionalität an
                </p>

                <div class="modules-grid">
                    <div
                        v-for="module in availableModules"
                        :key="module.id"
                        class="module-card"
                        :class="{
                            'module-card--selected': selectedModules.includes(module.id),
                            'module-card--included': isModuleIncluded(module.id),
                            'module-card--unavailable': !selectedPlan,
                        }"
                        @click="toggleModule(module.id)"
                    >
                        <div class="module-icon">
                            <i :class="module.icon"></i>
                        </div>

                        <div class="module-content">
                            <h4 class="module-title">{{ module.name }}</h4>
                            <p class="module-description">{{ module.description }}</p>
                        </div>

                        <div class="module-footer">
                            <div class="module-price" v-if="!isModuleIncluded(module.id)">
                                {{ formatPrice(module.price) }}€ <span class="period">/ monat</span>
                            </div>
                            <div class="module-included" v-else>
                                <i class="ri-check-double-line"></i> Im Tarif enthalten
                            </div>

                            <div class="module-select">
                                <div
                                    class="toggle-switch"
                                    :class="{
                                        'toggle-switch--on': selectedModules.includes(module.id),
                                        'toggle-switch--disabled':
                                            isModuleIncluded(module.id) || !selectedPlan,
                                    }"
                                >
                                    <div class="toggle-slider"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Total Calculation & Checkout -->
            <div class="checkout-section" v-if="hasChangedPlan || selectedModules.length > 0">
                <div class="order-summary">
                    <div class="summary-header" @click="toggleSummaryDetails">
                        <h3>Bestellübersicht:</h3>
                        <div class="summary-collapsed" v-if="selectedPlan">
                            <span class="summary-info">
                                {{ getSelectedPlanName() }} Tarif +
                                {{ selectedModules.length }} Module
                            </span>
                            <i
                                class="toggle-icon ri-arrow-down-s-line"
                                :class="{ expanded: summaryExpanded }"
                            ></i>
                        </div>
                    </div>

                    <div
                        v-if="selectedPlan"
                        class="summary-details"
                        :class="{ expanded: summaryExpanded }"
                    >
                        <div class="summary-items">
                            <div class="summary-item">
                                <span class="item-name">{{ getSelectedPlanName() }} Tarif</span>
                                <span class="item-price">{{ getSelectedPlanPrice() }}€</span>
                            </div>

                            <div
                                v-for="moduleId in selectedModules"
                                :key="moduleId"
                                class="summary-item"
                            >
                                <span class="item-name">{{ getModuleName(moduleId) }}</span>
                                <span class="item-price">{{ getModulePrice(moduleId) }}€</span>
                            </div>
                        </div>
                    </div>

                    <div v-else class="empty-selection">
                        <i class="ri-information-line"></i>
                        <p>Bitte wählen Sie einen Tarif</p>
                    </div>

                    <div v-if="selectedPlan" class="summary-total">
                        <span class="total-label">Gesamt (monatlich):</span>
                        <span class="total-price">{{ calculateTotal() }}€</span>
                    </div>

                    <div class="tax-info">
                        <i class="ri-information-line"></i>
                        <span>Alle Preise zzgl. MwSt.</span>
                    </div>
                </div>

                <Button
                    variant="warning"
                    :disabled="!selectedPlan || isProcessing"
                    @onClick="handleCheckout"
                >
                    {{ isProcessing ? 'Wird bearbeitet...' : 'Jetzt upgraden' }}
                    <i class="ri-arrow-right-up-line" v-if="!isProcessing"></i>
                    <span v-if="isProcessing" class="loading-spinner"></span>
                </Button>
            </div>
        </div>

        <!-- Feature Comparison Table -->
        <div class="feature-comparison">
            <h2 class="comparison-title">Funktionsvergleich der Pläne</h2>

            <div class="comparison-table-container">
                <table class="comparison-table">
                    <thead>
                        <tr>
                            <th class="feature-col">Funktion</th>
                            <th class="lite-col">Lite</th>
                            <th class="basic-col">Basic</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(feature, index) in featureComparison" :key="index">
                            <td class="feature-name">{{ feature.name }}</td>
                            <td class="lite-value">
                                <i
                                    :class="
                                        feature.lite
                                            ? 'ri-check-line included'
                                            : 'ri-close-line not-included'
                                    "
                                ></i>
                                <span v-if="typeof feature.lite === 'string'">{{
                                    feature.lite
                                }}</span>
                            </td>
                            <td class="basic-value">
                                <i
                                    :class="
                                        feature.basic
                                            ? 'ri-check-line included'
                                            : 'ri-close-line not-included'
                                    "
                                ></i>
                                <span v-if="typeof feature.basic === 'string'">{{
                                    feature.basic
                                }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- FAQ Section -->
        <div class="faq-section">
            <h2 class="faq-title">Häufig gestellte Fragen</h2>

            <div class="faq-container">
                <div
                    v-for="(faq, index) in faqs"
                    :key="index"
                    class="faq-item"
                    :class="{ 'faq-item--open': openFaq === index }"
                    @click="toggleFaq(index)"
                >
                    <div class="faq-question">
                        <h4>{{ faq.question }}</h4>
                        <i
                            :class="
                                openFaq === index ? 'ri-arrow-up-s-line' : 'ri-arrow-down-s-line'
                            "
                        ></i>
                    </div>
                    <div class="faq-answer" v-if="openFaq === index">
                        <p>{{ faq.answer }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from '@/components/widgets/Button.vue';
import { mapGetters } from 'vuex';

export default {
    name: 'PricingUpgrade',
    components: {
        Button,
    },
    data() {
        return {
            selectedPlan: '',
            hasChangedPlan: false,
            selectedModules: [],
            isProcessing: false,
            openFaq: null,
            summaryExpanded: false,
            plans: [
                {
                    id: 'lite',
                    name: 'Lite',
                    price: 10,
                    description: 'Gesetzliche Anforderungen erfüllen',
                    features: [
                        'Datenspeicherung in Echtzeit',
                        'SIM-Karte ohne Vertragsbindung',
                        'TSE Gateway Gebühren inklusive',
                        'Mitarbeiterverwaltung',
                        'Schichtmanagement',
                    ],
                },
                {
                    id: 'basic',
                    name: 'Basic',
                    price: 16,
                    description: 'Optimal für Unternehmen mit mehreren Fahrzeugen',
                    features: [
                        'Alles in Lite',
                        'Dashboard',
                        'Betriebsnachweise',
                        'Arbeitszeitnachweise',
                        'Integrierte taxiFusion Driver App',
                    ],
                },
            ],

            modules: [
                {
                    id: 'dashboard',
                    name: 'Dashboard',
                    description:
                        'Übersichtliches Dashboard mit allen wichtigen Kennzahlen auf einen Blick.',
                    price: 1.0,
                    icon: 'ri-dashboard-line',
                    includedIn: ['basic'],
                },
                {
                    id: 'betriebsnachweise',
                    name: 'Betriebsnachweise',
                    description:
                        'Vollständige Dokumentation aller betrieblichen Aktivitäten gemäß gesetzlicher Anforderungen.',
                    price: 2.0,
                    icon: 'ri-file-list-3-line',
                    includedIn: ['basic'],
                },
                {
                    id: 'arbeitszeitnachweise',
                    name: 'Arbeitszeitnachweise',
                    description:
                        'Umfassende Erfassung und Verwaltung der Arbeitszeiten Ihrer Fahrer.',
                    price: 3.0,
                    icon: 'ri-time-line',
                    includedIn: ['basic'],
                },
                {
                    id: 'performance-analysis',
                    name: 'Performance-Analyse',
                    description:
                        'Detaillierte Einblicke in die Leistung Ihrer Fahrer und Fahrzeuge für eine optimierte betriebliche Effizienz.',
                    price: 4.99,
                    icon: 'ri-bar-chart-2-line',
                    includedIn: ['ultimate'],
                },
                {
                    id: 'reminder-functions',
                    name: 'Erinnerungsfunktionen',
                    description:
                        'Erinnerungen für Reifen und Ölwechsel, Inspektionen, TÜV, P-Schein-Verlängerung und mehr. Verpassen Sie nie wieder wichtige Termine.',
                    price: 3.99,
                    icon: 'ri-alarm-warning-line',
                    includedIn: [],
                },
                {
                    id: 'driver-logs',
                    name: 'Fahrtenbuch',
                    description:
                        'Umfassendes digitales Fahrtenbuch für Reiseaufzeichnung und Steuerkonformität.',
                    price: 4.99,
                    icon: 'ri-book-line',
                    includedIn: ['ultimate'],
                },
                {
                    id: 'scheduler',
                    name: 'Schichtplaner',
                    description:
                        'Anspruchsvolle Schichtplanung und Tools zur Optimierung von Zeitplänen.',
                    price: 5.99,
                    icon: 'ri-time-line',
                    includedIn: ['ultimate'],
                },
                {
                    id: 'savings-calculator',
                    name: 'Rücklagenrechner',
                    description: 'Finanzplanungstools für Budgetierung und Rücklagen.',
                    price: 3.99,
                    icon: 'ri-calculator-line',
                    includedIn: [],
                },
            ],
            featureComparison: [
                { name: 'Datenspeicherung in Echtzeit', lite: true, basic: true },
                { name: 'SIM-Karte ohne Vertragsbindung', lite: true, basic: true },
                { name: 'TSE Gateway Gebühren inklusive', lite: true, basic: true },
                { name: 'Mitarbeiterverwaltung', lite: true, basic: true },
                { name: 'Schichtmanagement', lite: true, basic: true },
                { name: 'Betriebsnachweise', lite: false, basic: true },
                { name: 'Arbeitszeitnachweise', lite: false, basic: true },
                { name: 'Dashboard', lite: false, basic: true },
                { name: 'Integrierte Driver App', lite: false, basic: true },
                { name: 'E-Mail-Support', lite: 'Standard', basic: 'Priorität' },
            ],

            faqs: [
                {
                    question: 'Kann ich jederzeit vom Lite- zum Basic-Tarif wechseln?',
                    answer:
                        'Ja, Sie können Ihren Tarif jederzeit upgraden. Ihre Abrechnung wird anteilig für den Rest Ihres Abrechnungszeitraums angepasst.',
                },
                {
                    question: 'Verliere ich meine Daten beim Upgrade?',
                    answer:
                        'Nein, alle Ihre vorhandenen Daten bleiben beim Upgrade erhalten. Sie erhalten lediglich Zugriff auf zusätzliche Funktionen.',
                },
                {
                    question: 'Wie funktioniert die Abrechnung für Zusatzmodule?',
                    answer:
                        'Zusatzmodule werden mit derselben Häufigkeit wie Ihr Hauptplan abgerechnet. Sie können Module jederzeit hinzufügen oder entfernen, wobei Abrechnungsanpassungen auf Ihrer nächsten Rechnung erscheinen.',
                },
                {
                    question: 'Kann ich nach einem Upgrade wieder zum Lite-Tarif zurückkehren?',
                    answer:
                        'Nach dem Upgrade besteht eine Vertragslaufzeit von 12 Monaten für den Basic-Tarif. Nach Ablauf dieser Mindestvertragslaufzeit können Sie zum Lite-Tarif zurückkehren, wenn Sie dies wünschen.',
                },
                {
                    question: 'Muss ich nach dem Upgrade etwas Neues installieren?',
                    answer:
                        'Nein, TaxiFusion ist eine Cloud-basierte Lösung. Alle neuen Funktionen sind nach dem Upgrade sofort in Ihrem Konto verfügbar.',
                },
                {
                    question: 'Wie funktioniert der kostenlose Testzeitraum?',
                    answer:
                        'Als Lite-Nutzer können Sie den Basic-Tarif einen Monat lang kostenlos testen. Nach Ablauf des Testzeitraums verlängert sich Ihr Upgrade automatisch zum regulären Preis, sofern Sie nicht ausdrücklich zum Lite-Tarif zurückkehren. Sie können das Upgrade jederzeit monatlich kündigen und zum Lite-Tarif zurückkehren. Diese Entscheidung können Sie in Ihren Kontoeinstellungen vornehmen.',
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['planType']),

        currentPlan() {
            return this.planType || 'lite';
        },

        availableModules() {
            return this.modules.map(module => ({
                ...module,
                isIncluded: this.isModuleIncluded(module.id),
            }));
        },
    },
    methods: {
        selectPlan(planId) {
            this.selectedPlan = planId;
            this.hasChangedPlan = true;
            // Reset selected modules to avoid conflicts
            this.selectedModules = this.selectedModules.filter(moduleId => {
                const module = this.modules.find(m => m.id === moduleId);
                return module && !module.includedIn.includes(planId);
            });
        },

        startFreeTrial() {
            this.selectPlan('basic');
            this.$toasted.show(
                'Ihr kostenloser Testzeitraum wird nach der Bestätigung aktiviert!',
                {
                    type: 'success',
                    duration: 5000,
                },
            );
            this.handleCheckout(true);
        },
        toggleSummaryDetails() {
            this.summaryExpanded = !this.summaryExpanded;
        },
        toggleModule(moduleId) {
            if (!this.selectedPlan) return;

            const module = this.modules.find(m => m.id === moduleId);
            if (module && module.includedIn.includes(this.selectedPlan)) {
                return; // Already included in plan
            }

            if (this.selectedModules.includes(moduleId)) {
                this.selectedModules = this.selectedModules.filter(id => id !== moduleId);
            } else {
                this.selectedModules.push(moduleId);
            }
        },

        isModuleIncluded(moduleId) {
            if (!this.selectedPlan) return false;
            const module = this.modules.find(m => m.id === moduleId);
            return module && module.includedIn.includes(this.selectedPlan);
        },

        getSelectedPlanName() {
            const plan = this.plans.find(p => p.id === this.selectedPlan);
            return plan ? plan.name : '';
        },

        getSelectedPlanPrice() {
            const plan = this.plans.find(p => p.id === this.selectedPlan);
            return plan ? plan.price : 0;
        },

        getModuleName(moduleId) {
            const module = this.modules.find(m => m.id === moduleId);
            return module ? module.name : '';
        },

        getModulePrice(moduleId) {
            const module = this.modules.find(m => m.id === moduleId);
            return module ? module.price : 0;
        },

        calculateTotal() {
            let total = 0;

            // Add plan price
            const plan = this.plans.find(p => p.id === this.selectedPlan);
            if (plan) {
                total += plan.price;
            }

            // Add module prices (only if not included in plan)
            this.selectedModules.forEach(moduleId => {
                if (!this.isModuleIncluded(moduleId)) {
                    const module = this.modules.find(m => m.id === moduleId);
                    if (module) {
                        total += module.price;
                    }
                }
            });

            return total.toFixed(2);
        },

        formatPrice(price) {
            return Number(price).toFixed(2);
        },

        toggleFaq(index) {
            this.openFaq = this.openFaq === index ? null : index;
        },

        async handleCheckout(isTrial = false) {
            if (!this.selectedPlan) return;

            this.isProcessing = true;

            try {
                // Simulate API call
                await new Promise(resolve => setTimeout(resolve, 1500));

                // In real implementation, send selectedPlan and selectedModules to backend
                console.log('Checkout with:', {
                    plan: this.selectedPlan,
                    modules: this.selectedModules,
                    isFreeTrialActivation: isTrial,
                });
            } catch (error) {
                console.error('Checkout error:', error);
            } finally {
                this.isProcessing = false;
            }
        },
    },
    mounted() {
        // Set current plan as default selected
        this.selectedPlan = this.currentPlan;
        window.scrollTo(0, 0);
        this.$emit('onFinishLoading');
    },
};
</script>
<style lang="scss">
.pricing-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 60px 20px 120px;
    color: var(--color-text);

    .pricing-header {
        margin-bottom: 60px;

        h1 {
            font-size: 42px;
            font-weight: 800;
            margin-bottom: 16px;
            background: linear-gradient(90deg, var(--color-blue-darker), var(--color-blue-light));
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            line-height: 1.2;
        }

        .subtitle {
            font-size: 18px;
        }
    }

    .pricing-container {
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px;
    }

    // Free Trial Banner
    .free-trial-banner {
        background: linear-gradient(
            135deg,
            var(--color-green-dark) 0%,
            var(--color-green-dark) 100%
        );
        border-radius: 16px;
        padding: 24px 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        margin-bottom: 40px;
        box-shadow: 0 10px 30px rgba(9, 212, 144, 0.2);

        .banner-content {
            display: flex;
            align-items: center;
            gap: 20px;

            i {
                font-size: 40px;
            }

            .banner-text {
                h3 {
                    font-size: 22px;
                    font-weight: 700;
                    margin-bottom: 5px;
                    color: white;
                }

                p {
                    font-size: 16px;
                    opacity: 0.9;
                }
            }
        }

        .trial-button {
            background-color: white;
            color: var(--color-green-dark);
            font-weight: 700;
            padding: 12px 24px;

            &:hover {
                background-color: rgba(255, 255, 255, 0.9);
                box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.3);
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;
            padding: 24px;

            .banner-content {
                margin-bottom: 20px;
            }

            .trial-button {
                width: 100%;
            }
        }
    }

    // Tarif Cards
    .plan-cards-container {
        display: flex;
        justify-content: center;
        gap: 30px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .plan-card {
        background: white;
        border-radius: 16px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
        padding: 40px;
        width: 100%;
        max-width: 380px;
        transition: all 0.3s ease;
        position: relative;
        display: flex;
        flex-direction: column;
        border: 2px solid transparent;

        .plan-badge {
            position: absolute;
            top: -12px;
            right: 20px;
            background: var(--color-warning);
            color: var(--color-text-black);
            padding: 5px 15px;
            border-radius: 20px;
            font-weight: 700;
            font-size: 14px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        }

        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 15px 35px rgba(0, 0, 0, 0.12);
        }

        &--selected {
            border-color: var(--color-blue-dark);
            transform: translateY(-5px);
            box-shadow: 0 15px 35px rgba(9, 9, 9, 0.15);

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 6px;
                background: var(--color-blue-dark);
                border-radius: 16px 16px 0 0;
            }
        }

        &--lite {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 50%;
                height: 50%;
                background: linear-gradient(
                    135deg,
                    rgba(var(--color-blue-lighter), 0.05) 0%,
                    rgba(var(--color-blue-light), 0.05) 100%
                );
                border-radius: 0 16px 0 50%;
                z-index: 0;
            }
        }

        &--basic {
            &::after {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                width: 50%;
                height: 50%;
                background: linear-gradient(
                    135deg,
                    rgba(9, 212, 144, 0.05) 0%,
                    rgba(35, 157, 116, 0.05) 100%
                );
                border-radius: 0 16px 0 50%;
                z-index: 0;
            }
        }

        &__header {
            position: relative;
            z-index: 1;
            margin-bottom: 30px;

            .plan-name {
                font-size: 26px;
                font-weight: 700;
                margin-bottom: 16px;
            }

            .plan-price {
                display: flex;
                flex-direction: column;

                .amount {
                    font-size: 42px;
                    font-weight: 800;
                    color: var(--color-text-black);
                }

                .period {
                    font-size: 14px;

                    margin-top: 4px;
                }
            }
        }
        .discount-info {
            background-color: #f5f9ff;
            padding: 15px;
            border-radius: 8px;
            margin: 20px 0;

            .discount-label {
                font-weight: 600;
                color: var(--color-blue-dark);
                display: block;
                margin-bottom: 8px;
            }

            .discount-list {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    position: relative;
                    padding-left: 15px;
                    margin-bottom: 5px;
                    font-size: 14px;

                    &:before {
                        content: '•';
                        position: absolute;
                        left: 0;
                        color: var(--color-blue-dark);
                    }
                }
            }
        }

        &__body {
            flex: 1;
            position: relative;
            z-index: 1;

            .plan-description {
                margin-bottom: 30px;
                font-size: 16px;
            }

            .feature-list {
                padding: 0;
                list-style: none;
                margin-bottom: 40px;

                .feature-item {
                    display: flex;
                    align-items: flex-start;
                    margin-bottom: 16px;

                    .feature-icon {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 24px;
                        height: 24px;
                        background: var(--color-green-dark);
                        border-radius: 50%;
                        margin-right: 12px;
                        flex-shrink: 0;

                        i {
                            color: var(--color-white);
                            font-size: 14px;
                        }
                    }

                    .feature-text {
                        font-size: 15px;
                        line-height: 1.5;
                        color: var(--color-text-blue);
                    }
                }
            }
        }

        &__footer {
            position: relative;
            z-index: 1;

            .select-plan-btn {
                width: 100%;
                font-weight: 600;
                height: 48px;
                font-size: 16px;
                transition: all 0.3s ease;
            }

            .current-plan-badge {
                margin-top: 16px;
                font-size: 14px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--color-blue-dark);

                i {
                    margin-right: 6px;
                    font-size: 16px;
                }
            }
        }
    }

    // Modules Section
    .modules-section {
        transition: opacity 0.3s ease;

        &--dimmed {
            opacity: 0.6;
            pointer-events: none;
        }

        .section-title {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 12px;
        }

        .section-subtitle {
            margin-bottom: 40px;
            font-size: 16px;
        }

        .modules-grid {
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
            gap: 24px;

            @media (max-width: 640px) {
                grid-template-columns: 1fr;
            }
        }
        .module-card {
            background: white;
            border-radius: 12px;
            padding: 24px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0);
            transition: all 0.3s ease;
            cursor: pointer;
            border: 1px solid #e2e8f0;
            display: flex;
            flex-direction: column;
            position: relative;
            overflow: hidden;

            &:hover {
                box-shadow: 0 8px 24px rgba(0, 0, 0, 0.09);
                transform: translateY(-2px);
            }

            &--selected {
                border-color: var(--color-blue-dark);
                box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);

                .toggle-switch {
                    background: var(--color-blue-dark);
                }
            }

            &--included {
                border-color: var(--color-blue-lighter);
                background: rgba(71, 78, 122, 0.03);

                &::before {
                    content: 'Enthalten';
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: var(--color-blue-lighter);
                    color: white;
                    padding: 4px 12px;
                    font-size: 10px;
                    font-weight: 600;
                    border-radius: 0 10px 0 12px;
                    text-transform: uppercase;
                }

                .module-icon i {
                    color: var(--color-blue-lighter);
                }
            }

            &--unavailable {
                opacity: 0.6;
                cursor: not-allowed;
            }

            .module-icon {
                margin-bottom: 16px;

                i {
                    font-size: 28px;
                    color: var(--color-blue-dark);
                }
            }

            .module-content {
                flex: 1;

                .module-title {
                    font-size: 18px;
                    font-weight: 600;
                    margin-bottom: 8px;
                }

                .module-description {
                    font-size: 14px;
                    line-height: 1.5;
                    margin-bottom: 20px;
                }
            }

            .module-footer {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .module-price {
                    font-weight: 600;
                    font-size: 18px;

                    .period {
                        font-size: 12px;
                        font-weight: normal;
                    }
                }

                .module-included {
                    color: var(--color-blue-lighter);
                    font-weight: 600;
                    font-size: 14px;
                    display: flex;
                    align-items: center;

                    i {
                        margin-right: 4px;
                        font-size: 16px;
                    }
                }

                .toggle-switch {
                    width: 44px;
                    height: 24px;
                    background: #e2e8f0;
                    border-radius: 50px;
                    position: relative;
                    transition: all 0.3s ease;

                    &--on {
                        background: var(--color-blue-dark);

                        .toggle-slider {
                            transform: translateX(20px);
                        }
                    }

                    &--disabled {
                        opacity: 0.5;
                        cursor: not-allowed;
                    }

                    .toggle-slider {
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        width: 20px;
                        height: 20px;
                        background: white;
                        border-radius: 50%;
                        transition: all 0.3s ease;
                        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
                    }
                }
            }
        }
    }

    // Checkout Section
    .checkout-section {
        position: sticky;
        bottom: 0;
        background: white;
        border-radius: 12px 12px 0 0;
        box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.08);
        padding: 12px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 40px;
        z-index: 10;
        transition: all 0.3s ease;

        @media (max-width: 768px) {
            flex-direction: column;
            padding: 12px 16px;
            align-items: stretch;
        }

        .order-summary {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            position: relative;

            @media (max-width: 768px) {
                margin-bottom: 12px;
            }

            .summary-header {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                h3 {
                    font-size: 16px;
                    font-weight: 600;
                    white-space: nowrap;
                    margin: 0;
                }

                .summary-collapsed {
                    display: flex;
                    align-items: center;

                    .summary-info {
                        margin-left: 10px;
                        font-size: 14px;
                    }

                    .toggle-icon {
                        font-size: 18px;
                        color: var(--color-blue-light);
                        transition: transform 0.3s ease;

                        &.expanded {
                            transform: rotate(180deg);
                        }
                    }
                }
            }

            .summary-details {
                overflow: hidden;
                max-height: 0;
                transition: max-height 0.3s ease;
                width: 100%;

                &.expanded {
                    max-height: 300px; /* Arbitrary large value */
                    margin-top: 10px;
                }

                .summary-items {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                }

                .summary-item {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 4px 0;
                    border-bottom: 1px dashed rgba(0, 0, 0, 0.05);

                    &:last-child {
                        border-bottom: none;
                    }

                    .item-name {
                        font-size: 14px;
                    }

                    .item-price {
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }

            .summary-total {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                margin-top: 10px;
                padding-top: 6px;
                border-top: 1px solid #eee;

                @media (max-width: 768px) {
                    margin-top: 8px;
                }

                .total-label {
                    font-size: 14px;
                    font-weight: 500;
                }

                .total-price {
                    font-size: 18px;
                    font-weight: 700;
                    color: var(--color-blue-dark);
                }
            }

            .tax-info {
                display: flex;
                align-items: center;
                width: 100%;
                font-size: 12px;
                margin-top: 6px;

                i {
                    font-size: 14px;
                    margin-right: 4px;
                }
            }

            .empty-selection {
                display: flex;
                align-items: center;
                color: var(--color-text-inactive);
                padding: 5px 0;

                i {
                    font-size: 16px;
                    margin-right: 8px;
                    color: var(--color-blue-light);
                }

                p {
                    margin: 0;
                    font-size: 14px;
                }
            }
        }

        .additional-info {
            display: flex;
            align-items: center;
            font-size: 12px;
            color: var(--color-text-gray);
            margin-left: 15px;

            .setup-fee-notice {
                display: flex;
                align-items: center;
                margin-right: 15px;

                i {
                    font-size: 14px;
                    margin-right: 4px;
                }
            }

            @media (max-width: 768px) {
                margin-left: 0;
                margin-bottom: 10px;
                font-size: 11px;
            }
        }

        .checkout-button {
            height: 40px;
            min-width: 140px;
            padding: 0 16px;
            font-size: 14px;
            font-weight: 600;
            display: flex;
            align-items: center;
            justify-content: center;
            white-space: nowrap;

            i {
                margin-right: 6px;
                font-size: 16px;
            }

            .loading-spinner {
                width: 16px;
                height: 16px;
                border: 2px solid rgba(255, 255, 255, 0.3);
                border-radius: 50%;
                border-top-color: white;
                animation: spin 1s ease-in-out infinite;
                margin-right: 8px;
            }

            @keyframes spin {
                to {
                    transform: rotate(360deg);
                }
            }
        }
    }
    .feature-comparison {
        margin-top: 100px;

        .comparison-title {
            font-size: 28px;
            font-weight: 700;

            margin-bottom: 40px;
        }

        .comparison-table-container {
            overflow-x: auto;
            box-shadow: 0 10px 30px rgba(0, 0, 0, 0.06);
            border-radius: 12px;
            background: white;
        }

        .comparison-table {
            width: 100%;
            border-collapse: collapse;

            th,
            td {
                padding: 16px 24px;
                text-align: left;
                border-bottom: 1px solid #e2e8f0;
            }

            th {
                font-weight: 600;
                color: var(--color-text-blue);
                font-size: 16px;
                background: var(--color-bg);

                &.feature-col {
                    width: 40%;
                }

                &.lite-col,
                &.basic-col {
                    width: 30%;
                }

                &.lite-col {
                    color: var(--color-blue-lighter);
                }

                &.basic-col {
                    color: var(--color-blue-dark);
                }
            }

            td {
                font-size: 15px;
                color: var(--color-text-blue);

                &.feature-name {
                    font-weight: 500;
                }

                &.lite-value,
                &.basic-value {
                    i {
                        font-size: 20px;

                        &.included {
                            color: var(--color-blue-dark);
                        }

                        &.not-included {
                            color: var(--color-red);
                        }
                    }

                    span {
                        font-weight: 600;
                        margin-left: 4px;
                    }
                }
            }

            tr:last-child td {
                border-bottom: none;
            }

            tr:hover td {
                background: var(--color-bg);
            }
        }
    }

    // FAQ Section
    .faq-section {
        margin-top: 100px;

        .faq-title {
            font-size: 28px;
            font-weight: 700;

            margin-bottom: 40px;
        }

        .faq-container {
        }

        .faq-item {
            background: white;
            border-radius: 12px;
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
            margin-bottom: 16px;
            overflow: hidden;
            transition: all 0.3s ease;

            &:hover {
                box-shadow: 0 8px 24px rgba(0, 0, 0, 0.09);
            }

            &--open {
                box-shadow: 0 8px 24px rgba(0, 0, 0, 0.09);
            }

            .faq-question {
                padding: 20px 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                cursor: pointer;

                h4 {
                    font-size: 17px;
                    font-weight: 600;
                    margin: 0;
                    color: var(--color-text-blue);
                }

                i {
                    font-size: 24px;
                    color: var(--color-blue-light);
                    transition: transform 0.3s ease;
                }
            }

            .faq-answer {
                padding: 0 24px 20px;
                line-height: 1.6;

                @keyframes fadeIn {
                    from {
                        opacity: 0;
                        transform: translateY(-10px);
                    }
                    to {
                        opacity: 1;
                        transform: translateY(0);
                    }
                }
            }
        }
    }
}

// Button variants - using existing Button component styles
.Button {
    &.is-primary {
        background: var(--color-blue-dark);
        color: white;

        &:hover {
            background: var(--color-blue-light);
        }
    }

    &.is-outline {
        background: transparent;
        border: 2px solid var(--color-blue-light);
        color: var(--color-blue-light);

        &:hover {
            background: rgba(71, 78, 122, 0.1);
        }
    }

    &.is-warning {
        color: var(--color-text-black);

        &:hover {
        }

        &:disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }
    }

    &.is-plain {
        color: var(--color-text-blue);
        background-color: transparent;

        &:hover {
            background: rgba(71, 78, 122, 0.1);
            border-color: var(--color-blue-light);
        }
    }
}
</style>
